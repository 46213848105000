import beauty1 from "./images/beauty/beauty1.jpg"
import beauty2 from "./images/beauty/beauty2.jpg"
import beauty3 from "./images/beauty/beauty3.jpg"
import beauty4 from "./images/beauty/beauty4.jpg"
import beauty5 from "./images/beauty/beauty5.jpg"
import beauty6 from "./images/beauty/beauty6.jpg"
import beauty7 from "./images/beauty/beauty7.jpeg"
import beauty8 from "./images/beauty/beauty8.jpg"
import beauty9 from "./images/beauty/beauty9.jpg"
import beauty10 from "./images/beauty/beauty10.jpg"
import beauty11 from "./images/beauty/beauty11.jpeg"
import beauty12 from "./images/beauty/beauty12.jpg"
import beauty13 from "./images/beauty/beauty13.jpg"
import beauty14Hair2 from "./images/beauty/beauty14hair2.jpg"
import beauty15 from "./images/beauty/beauty15.jpg"
import beauty16 from "./images/beauty/beauty16.jpg"
import beauty17 from "./images/beauty/beauty17.jpg"
import beauty18 from "./images/beauty/beauty18.jpg"
import beauty19 from "./images/beauty/beauty19.jpg"
import beauty20 from "./images/beauty/beauty20.jpg"
import beauty21 from "./images/beauty/beauty21.jpeg"
import beauty22 from "./images/beauty/beauty22.jpeg"
import beauty23 from "./images/beauty/beauty23.jpeg"
import beauty24 from "./images/beauty/beauty24.jpg"
import beauty25Hair8 from "./images/beauty/beauty25-hair8.jpeg"
import beauty26 from "./images/beauty/beauty26.jpeg"
import beauty27 from "./images/beauty/beauty27.jpeg"
import beauty28 from "./images/beauty/beauty28.jpg"
import beauty29 from "./images/beauty/beauty29.jpeg"
import beauty30 from "./images/beauty/beauty30.jpeg"
import beauty31 from "./images/beauty/beauty31.jpeg"
import beauty32 from "./images/beauty/beauty32.jpeg"
import beauty33 from "./images/beauty/beauty33.jpeg"
import beauty34 from "./images/beauty/beauty34.jpeg"
import beauty35 from "./images/beauty/beauty35.jpeg"
import beauty36 from "./images/beauty/beauty36.jpeg"
import beauty37 from "./images/beauty/beauty37.jpg"

import fashion1 from "./images/fashion/fashion1.jpeg"
import fashion2 from "./images/fashion/fashion2.jpg"
import fashion3 from "./images/fashion/fashion3.jpg"
import fashion4 from "./images/fashion/fashion4.jpg"
import fashion5 from "./images/fashion/fashion5.jpg"
import fashion6 from "./images/fashion/fashion6.jpg"
import fashion7 from "./images/fashion/fashion7.jpg"
import fashion8 from "./images/fashion/fashion8.jpeg"
import fashion9 from "./images/fashion/fashion9.jpeg"
import fashion10 from "./images/fashion/fashion10.jpeg"
import fashion11 from "./images/fashion/fashion11.jpeg"
import fashion12 from "./images/fashion/fashion12.jpeg"
import fashion13 from "./images/fashion/fashion13.jpeg"

import hair1 from "./images/hair/hair1.jpg"
import hair3 from "./images/hair/hair3.jpg"
import hair4 from "./images/hair/hair4.jpg"
import hair5 from "./images/hair/hair5.png"
import hair6 from "./images/hair/hair6.jpeg"
import hair7 from "./images/hair/hair7.jpeg"
import hair9 from "./images/hair/hair9.jpg"
import hair10 from "./images/hair/hair10.jpeg"
import hair11 from "./images/hair/hair11.jpg"
import hair12 from "./images/hair/hair12.jpeg"
import hair13 from "./images/hair/hair13.jpg"
import hair14 from "./images/hair/hair14.jpg"
import hair15 from "./images/hair/hair15.jpg"
import hair16 from "./images/hair/hair16.jpg"
import hair17 from "./images/hair/hair17.jpeg"
import hair18 from "./images/hair/hair18.jpeg"
import hair19 from "./images/hair/hair19.png"
import hair20 from "./images/hair/hair20.jpeg"
import hair21 from "./images/hair/hair21.jpeg"
import hair22 from "./images/hair/hair22.jpeg"
import hair23 from "./images/hair/hair23.jpeg"
import hair24 from "./images/hair/hair24.jpeg"

import mg1 from "./images/maleGrooming/mg1.jpg"
import mg2 from "./images/maleGrooming/mg2.jpg"
import mg3 from "./images/maleGrooming/mg3.jpg"
import mg4 from "./images/maleGrooming/mg4.jpg"
import mg5 from "./images/maleGrooming/mg5.jpg"
import mg6 from "./images/maleGrooming/mg6.jpg"
import mg7 from "./images/maleGrooming/mg7.jpg"
import mg8 from "./images/maleGrooming/mg8.jpg"
import mg9 from "./images/maleGrooming/mg9.jpg"

export default {
    beauty: [beauty1,beauty2,beauty3,beauty4,beauty5,beauty6,beauty7,beauty8,beauty9,beauty10,beauty11,beauty12,beauty13,beauty14Hair2,beauty15,beauty16,beauty17,beauty18,beauty19,beauty20,beauty21,beauty22,beauty23,beauty24,beauty25Hair8,beauty26,beauty27,beauty28,beauty29,beauty30,beauty31,beauty32,beauty33,beauty34,beauty35,beauty36,beauty37],
    fashion: [fashion1,fashion2,fashion3,fashion4,fashion5,fashion6,fashion7,fashion8,fashion9,fashion10,fashion11,fashion12,fashion13],
    hair: [hair1,beauty14Hair2,hair3,hair4,hair5,hair6,hair7,beauty25Hair8,hair9,hair10,hair11,hair12,hair13,hair14,hair15,hair16,hair17,hair18,hair19,hair20,hair21,hair22,hair23,hair24],
    mg: [mg1,mg2,mg3,mg4,mg5,mg6,mg7,mg8,mg9]
};